(function () {

    document.addEventListener("DOMContentLoaded", () => {
        const featureToggleEl = document.querySelectorAll('.product-features__toggle'),
                productFeaturesSelect = document.getElementById('product-features-select'),
                productFeaturesValue = document.querySelectorAll('.product-features__value');

        let hideAllValues = () => {
            productFeaturesValue.forEach(function (item, index) {
                item.classList.add('hide');
            });
        }

        let showActiveValues = (className) => {
            productFeaturesValue.forEach(function (item, index) {
                if ( item.classList.contains(className) ) {
                    item.classList.remove('hide');
                }
            });
        }

        let resetValues = () => {
            productFeaturesValue.forEach(function (item, index) {
                item.classList.remove('hide');
            });
        }

        featureToggleEl.forEach(function (el) {
            el.addEventListener('click', (ev) => {
                el.classList.toggle('active');
            });
        });

        productFeaturesSelect.addEventListener('change', () => {
            hideAllValues();
            showActiveValues(productFeaturesSelect.value);
            
        });

        /**
         * Handles function calls in major breakpoints.
         */
         enquire.register("screen and (max-width:639px)", {
            match : function() {
                hideAllValues();
                showActiveValues(productFeaturesSelect.value);
            },
            unmatch : function() {
                resetValues();
            }
        });
    });

}());